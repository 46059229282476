@font-face {
	font-family: BebasNeue;
	src: url(../assets/fonts/BebasNeue.otf);
}
@font-face {
	font-family: neue-haas-thin;
	src: url(../assets/fonts/neue-haas/NeueHaasDisplayThin.ttf);
}
@font-face {
	font-family: neue-haas-bold;
	src: url(../assets/fonts/neue-haas/NeueHaasDisplayBold.ttf);
}
@font-face {
	font-family: garamond-regular;
	src: url(../assets/fonts/garamond-premier-pro/garamond-premier-pro-regular.ttf);
}
@font-face {
	font-family: garamond-bold;
	src: url(../assets/fonts/garamond-premier-pro/garamond-premier-pro-bold.otf);
}
@font-face {
	font-family: garamond-italic;
	src: url(../assets/fonts/garamond-premier-pro/garamond-premier-pro-italic-display.otf);
}
@font-face {
	font-family: Equal-Sans-Demo;
	src: url(../assets/fonts/EqualSans_Demo.ttf);
}

@font-face {
	font-family: NeutraText-Book;
	src: url(../assets/fonts/NeutraText-Book.otf);
}

@font-face {
	font-family: coves-light;
	src: url(../assets/fonts/Coves_Light.otf);
}
@font-face {
	font-family: coves-bold;
	src: url(../assets/fonts/Coves_Bold.otf);
}
