.modal-background {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: grid;
	justify-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;

	.modal-container {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		max-width: 1500px;
		width: calc(100% - 140px);
		align-content: center;
		margin-top: 50px;
		align-content: center;
		position: relative;
		overflow-y: scroll;
		border-radius: 5px;
	}
}

@keyframes scaleUp {
	0% {
		transform: scale(0.8) translateY(1000px);
		opacity: 0;
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
	}
}

@keyframes scaleDown {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 1;
	}
	100% {
		transform: scale(0.8) translateY(1000px);
		opacity: 0;
	}
}

@keyframes scaleBack {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.85);
	}
}

@keyframes scaleForward {
	0% {
		transform: scale(0.85);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes quickScaleDown {
	0% {
		transform: scale(1);
	}
	99.9% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@keyframes fadeIn {
	0% {
		background: rgba(0, 0, 0, 0);
	}
	100% {
		background: rgba(0, 0, 0, 0.7);
	}
}

@keyframes fadeOut {
	0% {
		background: rgba(0, 0, 0, 0.7);
	}
	100% {
		background: rgba(0, 0, 0, 0);
	}
}
