.project-card {
	position: absolute;
	top: 0;
	left: 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto;
	width: 100%;
	background-color: rgb(219, 234, 240);
	border-radius: 5px;
	animation-name: scaleUp;
	animation-duration: 0.3s;
	color: black;
	overflow-y: scroll;

	.title-bar {
		display: grid;
		grid-template-columns: auto auto;
		width: calc(100vw - 140px);
		max-width: 1500px;
		height: 90px;
		align-items: center;
		justify-content: space-between;
		background-color: rgb(172, 197, 235);
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		position: fixed;
		z-index: 3;

		.title {
			font-size: 40px;
			font-family: BebasNeue;
			letter-spacing: 25px;
			justify-self: flex-start;
			line-height: 1;
			margin: 0;
			margin-left: 30px;
		}

		.close {
			display: flex;
			justify-content: center;
			padding: 0 30px;

			.button-1 {
				width: 50px;
				height: 50px;
				box-shadow: -2px -2px 8px rgba(255, 255, 255, 0.8),
					2px 2px 8px rgba(25, 55, 99, 0.5);

				.inner {
					&:active {
						box-shadow: inset -2px -2px 5px rgba(255, 255, 255, 0.8),
							inset 2px 2px 5px rgba(25, 55, 99, 0.5);
					}
				}
				&:hover {
					box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.8),
						2px 2px 4px rgba(25, 55, 99, 0.5);
				}
			}
		}
	}

	.card-split {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 30px;
		padding: 30px;
		margin-top: 90px;

		.left {
			position: relative;
			display: grid;
			grid-template-columns: 1fr;
			height: 100%;

			.details {
				display: flex;
				justify-self: flex-start;
				width: 40px;
				font-size: 22px;
				font-family: BebasNeue;
				letter-spacing: 5px;
				line-height: 1;
				color: gray;
				border-bottom: 3px solid gray;
				padding-bottom: 10px;
				margin-top: -15px;
			}
			.row {
				display: flex;
				flex-direction: column;
				justify-items: center;
				gap: 5px;
				padding: 10px;

				.small-title {
					font-size: 18px;
					font-family: BebasNeue;
					letter-spacing: 5px;
					line-height: 1;
				}
				.text {
					display: flex;
					flex-direction: column;
					gap: 10px;
					width: 100%;
					font-size: 14px;
					max-width: 600px;
					p {
						font-family: 'Helvetica', 'Arial', sans-serif;
						font-size: 14px;
						font-weight: 400px;
						line-height: 20px;
					}
				}

				.icons {
					display: flex;
					gap: 90px;
					img {
						width: 50px;
						height: auto;
					}
				}
			}

			.corner-left {
				position: absolute;
				bottom: 0px;
				left: 0px;
				width: 30px;
				height: 30px;
				border-bottom: 3px solid grey;
				border-left: 3px solid grey;
			}
			.corner-right {
				position: absolute;
				top: 0px;
				right: 0px;
				width: 30px;
				height: 30px;
				border-top: 3px solid grey;
				border-right: 3px solid grey;
			}
		}

		.right {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr auto;
			gap: 30px;

			.image {
				width: 100%;
				height: 100%;
				padding: 10px;
				box-shadow: inset -2px -2px 5px rgb(255, 255, 255),
					inset 2px 2px 5px rgba(25, 55, 99, 0.3);
				box-sizing: border-box;
				border-radius: 5px;
				transition: box-shadow 0.2s ease;
				max-height: 400px;
				min-height: 350px;

				.image-preview {
					height: 100%;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 5px;
				}
				.royPreview {
					background-image: url(../assets/images/previews/RA_landing_preview.jpg);
				}
				.actionPreview {
					background-image: url(../assets/images/previews/AM_landing_preview.jpg);
				}

				&:hover {
					box-shadow: inset -2px -2px 3px rgb(255, 255, 255),
						inset 2px 2px 3px rgba(25, 55, 99, 0.3);
				}
			}

			.clickables {
				display: flex;
				gap: 30px;
				justify-content: center;
				padding: 0 10px;
				a {
					width: 70%;
					text-decoration: none;
					.button-1 {
						width: 100%;
						height: 50px;
					}
				}
			}
		}

		&#slider {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr auto;
			gap: 50px;

			.right {
				grid-template-rows: auto;
				gap: 0;
				justify-items: center;
				.image-slider {
					position: relative;
					width: 80%;
					img {
						width: 100%;
						border-radius: 5px;
						background-size: cover;
					}
					.flickity-page-dots {
						bottom: -20px;
						text-align: right;
					}
					.flickity-button {
						background: none;
					}
					.flickity-viewport {
						border-radius: 5px;
					}
					.flickity-prev-next-button.previous {
						left: -60px;
					}
					.flickity-prev-next-button.next {
						right: -60px;
					}
				}
			}
			.left {
				display: grid;
				grid-template-columns: 1fr 1fr;
				align-content: unset;
				.clickables {
					display: grid;
					a {
						text-decoration: none;
						.button-1 {
							width: 300px;
							height: 50px;
						}
					}
				}
			}
		}
	}

	@keyframes scaleUp {
		0% {
			transform: scale(0.8) translateY(1000px);
			opacity: 0;
		}
		100% {
			transform: scale(1) translateY(0px);
			opacity: 1;
		}
	}
	@keyframes scaleDown {
		0% {
			transform: scale(1) translateY(0px);
			opacity: 1;
		}
		100% {
			transform: scale(0.8) translateY(1000px);
			opacity: 0;
		}
	}
}
