#root {
	overflow: unset !important;
}
.pdf {
	display: grid;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: auto;
	}
}
