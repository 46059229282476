.roy-project {
	.fullscreen_video {
		position: relative;
		display: grid;
		width: 100%;
		height: 100vh;
	}

	.wrapper {
		position: relative;
	}

	.pad {
		padding: 20px;
	}

	.player {
		display: block;
		width: 100%;
		height: 100%;
	}

	.image {
		display: block;
		width: 32.5%;
	}

	.overlay {
		display: grid;
		position: absolute;
		width: 100%;
		height: 100vh;
		justify-items: center;
		align-items: center;
		z-index: 3;
	}

	.logo-small {
		display: block;
		width: 150px;
		height: 150px;
		justify-self: flex-end;
		filter: drop-shadow(5px 5px 5px rgb(0, 0, 0));
	}

	.title {
		display: grid;
		justify-content: center;
		h1 {
			font-family: BebasNeue;
			font-size: 65pt;
			color: white;
			text-shadow: 4px 4px 15px rgb(0, 0, 0);
			letter-spacing: 25px;
		}
	}

	.sub-title {
		display: block;
		justify-items: center;

		p {
			font-family: coves-light;
			font-size: x-large;
			color: white;
			text-shadow: 4px 4px 15px rgb(0, 0, 0);
			letter-spacing: 2px;
			line-height: 1.6;
		}
	}

	.header {
		display: grid;
		margin-left: 55%;
		h1 {
			font-family: BebasNeue;
			font-size: 5vw;
			color: rgb(0, 0, 0);
			letter-spacing: 20px;
		}
	}
	.text-30 {
		display: block;
		width: 30%;
		margin-right: 3%;

		p {
			font-family: coves-light;
			font-size: x-large;
			color: white;
			text-shadow: 2px 2px 10px rgb(0, 0, 0);
			letter-spacing: 2px;
			line-height: 1.6;
		}
	}

	.text-50 {
		display: block;
		margin-left: 55%;
		margin-right: 3%;
		align-content: center;

		p {
			font-family: coves-light;
			font-size: x-large;
			color: black;
			letter-spacing: 2px;
			line-height: 1.6;
		}
	}

	.text {
		margin: auto;
		width: 80%;

		p {
			font-family: coves-bold;
			font-size: x-large;
			color: rgb(255, 255, 255);
			text-shadow: 2px 2px 10px rgb(0, 0, 0);
			letter-spacing: 2px;
			line-height: 1.6;
		}
	}
	.aline-center {
		align-content: center;
		align-items: center;
	}

	.care {
		width: 20%;
	}

	.description {
		width: '500px';
	}

	#roy-title {
		color: black;
		text-shadow: 1px 1px 8px #ffffff;
	}
	#roy-logo {
		height: 100%;
		align-self: start;
	}

	.video_clip {
		display: grid;
		grid-template-columns: minmax(auto, 900px);
		width: 100%;
		justify-items: left;
		height: auto;
		padding: 20px;
	}

	.video_text_container {
		display: grid;
		gap: 20;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		place-content: center;
		height: 400px;
		padding: 20px;
		background-color: rgb(255, 255, 255);
	}

	.video_clip_small {
		display: grid;
		grid-template-columns: 1fr;
		align-self: start;
		background-color: rgb(255, 255, 255);
	}

	.five_swingtag {
		width: 100%;
	}
}
