.projects-grid {
	display: grid;
	grid-template-columns: repeat(3, 300px);
	grid-template-rows: 1fr;
	gap: 30px;
	justify-content: center;
	color: black;

	.col {
		display: grid;
		grid-template-columns: 1fr;
		height: 80vh;
		&.one {
			grid-template-rows: 1fr 1fr;
		}
		&.two {
			height: 90%;
			grid-template-rows: 1fr 1fr;
		}
		&.three {
			grid-template-rows: 1fr 1fr;
		}

		.card-wrap {
			position: relative;
			height: 100%;
			min-height: 150px;
			border-radius: 20px;
			background: transparent;
			.card-main {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.playful-card {
					width: 100%;
					height: 83%;
					border-radius: 5px;
					transition: box-shadow 0.5s;
					will-change: transform;
					display: grid;
					grid-template-columns: 1fr;
					padding: 15px;
					cursor: pointer;

					.cell-content {
						width: 100%;
						height: 100%;

						.name-hash {
							display: grid;
							grid-template-rows: auto auto;
							position: absolute;
							top: calc(100% - 40px);
							transition: all 300ms ease;
							padding-right: 10px;

							.cell-name {
								font-family: BebasNeue;
								font-size: 25px;
								line-height: 30px;
								letter-spacing: 5px;
							}
							.cell-tags {
								opacity: 0;
								font-family: coves-light;
								font-size: 20px;
								line-height: 30px;
								letter-spacing: 1px;
								color: rgb(0, 0, 0);
								transition: all 300ms ease;
							}
						}
					}
					&:hover {
						z-index: 100;
						.name-hash {
							transform: translateY(calc(-100% + 25px));
							transition-duration: 300ms;
							.cell-tags {
								opacity: 1;
								transition: opacity 200ms linear;
								transition-delay: 200ms;
							}
						}
					}
				}
			}
		}
	}

	.title {
		font-size: 80px;
		font-family: BebasNeue;
		letter-spacing: 10px;
		justify-self: flex-start;
		color: white;
		text-shadow: 2px 2px 5px #7a8abc;
		transition: text-shadow 0.3s;
		&:hover {
			text-shadow: 2px 2px 10px #5d6b96;
		}
	}
}

button {
	border: none;
	&:focus {
		outline: none;
	}
}

.button-1 {
	display: flex;
	justify-content: center;
	width: 70%;
	height: 50px;
	border-radius: 40px;
	text-align: center;
	padding: 1px 1px;
	z-index: 2;
	box-shadow: -2px -2px 8px rgb(255, 255, 255), 2px 2px 8px rgba(25, 55, 99, 0.3);
	background-color: transparent;
	transition: all 0.2s ease;
	cursor: pointer;
	overflow: hidden;

	.inner {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		justify-items: center;
		align-items: center;
		text-align: center;
		border-radius: 40px;
		font-size: 22px;
		font-family: BebasNeue;
		letter-spacing: 5px;

		.copy-to-clip {
			width: 30px;
		}

		&:active {
			box-shadow: inset -2px -2px 5px rgb(255, 255, 255),
				inset 2px 2px 5px rgba(25, 55, 99, 0.3);
		}
	}

	textarea {
		height: 0;
		width: 0;
		opacity: 0;
	}

	&:hover {
		box-shadow: -2px -2px 4px rgb(255, 255, 255), 2px 2px 4px rgba(25, 55, 99, 0.3);
	}
}

::-webkit-scrollbar {
	display: none;
}

.projects-section-wrap {
	width: 100vw;
	height: 100vh;
}
