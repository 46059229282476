$tab: 900px;
$mob: 500px;

body {
	color: white;
	h1 {
		font-family: neue-haas-bold;
		font-size: 90px;
		margin: 0;
		line-height: 1;
	}
	h2 {
		font-size: 80px;
		margin: 0;
		line-height: 1;
	}
	h3 {
		font-family: neue-haas-thin;
		font-size: 50px;
		margin: 0;
	}
	h4 {
		font-family: neue-haas-bold;
		font-size: 24px;
		margin: 0;
		letter-spacing: 1px;
	}
	h5 {
		font-family: garamond-bold;
		font-size: 35px;
		margin: 0;
	}
	h6 {
		font-family: neue-haas-thin;
		font-size: 25px;
		margin: 0;
	}
	p {
		font-family: garamond-regular;
		font-size: 20px;
		margin: 0;
		font-weight: 500;
		font-style: normal;
		margin-block-start: 0;
		margin-block-end: 0;
	}
	label,
	input,
	textarea {
		font-family: neue-haas-thin;
		font-size: 18px !important;
		margin: 0;
		font-weight: 400;
		font-style: normal;
		letter-spacing: 1px;
	}

	button {
		padding: 0;
	}
}

nav {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	width: 100%;
	position: fixed;
	z-index: 300;
	background-color: #323c7a;
	justify-items: flex-end;
	box-shadow: black 4px 0 10px;
	.navbar {
		display: flex;
		gap: 10px;
		padding: 10px;

		.nav-button {
			display: flex;
			align-items: center;
			padding: 2px;
			font-size: 25px;
			border-radius: 25px;
			color: #cccedd;
			background-color: transparent;
			transition: all 0.2s ease;
			box-shadow: -2px -2px 6px #727ec9, 2px 2px 6px #00000080;
			cursor: pointer;

			.inner {
				padding: 8px 15px;
				border-radius: 25px;
				&:active {
					box-shadow: inset -2px -2px 5px #727ec9, inset 2px 2px 5px #00000080;
				}
			}

			&:hover {
				color: #e4e5ee;
				box-shadow: -2px -2px 3px #727ec9, 2px 2px 3px #00000080;
			}
			&.active {
				color: white;

				box-shadow: -2px -2px 3px #727ec9, 2px 2px 3px #00000080;
				background-color: #1f265260;
				.inner {
					h6 {
						font-weight: 600;
					}
				}
			}
		}
	}
}

.background-stars {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background-color: #161b3a;
	background-image: url(../assets/images/LayeredScene/moon/stars.svg);
}

.page-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 100%;
	gap: 100px;

	.landing-page {
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		height: 100vh;
		align-items: center;
		align-content: center;
		padding: 0 90px;
		overflow: hidden;
		color: white;

		.moon-scene {
			.layers {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 100vh;
				.stars {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100vh;
					background-image: url(../assets/images/LayeredScene/moon/stars.svg);
				}
				.stars-2 {
					position: absolute;
					top: -100px;
					left: -850px;
					width: 250%;
					height: calc(100vh + 100px);
					background-image: url(../assets/images/LayeredScene/moon/stars.svg);
					background-size: cover;
				}
				.moon-and-light {
					.moon {
						position: absolute;
						width: 300px;
						top: calc(50% - 150px);
						left: calc(70% - 150px);
						height: 300px;
						background-image: url(../assets/images/LayeredScene/moon/moon.svg);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.moonlight {
						border-radius: 500px;
						background-color: #ffffff1a;
						position: absolute;
						width: 300px;
						height: 300px;
						opacity: 0.6;
						transition: all 0s ease;
						// transition-delay: 0s;
						&.m-1 {
							top: calc(50% - 200px);
							left: calc(70% - 200px);
							width: 400px;
							height: 400px;
							transition-duration: 0.1s;
						}
						&.m-2 {
							top: calc(50% - 250px);
							left: calc(70% - 250px);
							width: 500px;
							height: 500px;
							transition-duration: 0.3s;
						}
						&.m-3 {
							top: calc(50% - 300px);
							left: calc(70% - 300px);
							width: 600px;
							height: 600px;
							transition-duration: 0.4s;
						}
						&.m-4 {
							top: calc(50% - 350px);
							left: calc(70% - 350px);
							width: 700px;
							height: 700px;
							transition-duration: 0.5s;
						}
					}
					&:hover {
						.moonlight {
							opacity: 0.8;
						}
					}
				}

				.cloud {
					position: absolute;
					width: 100%;
					height: 200px;
					background-size: contain;
					background-repeat: no-repeat;
					&.c-1 {
						top: 20%;
						left: 45%;
						background-image: url(../assets/images/LayeredScene/moon/cloud_1.svg);
					}
					&.c-2 {
						top: 20%;
						left: 80%;
						background-image: url(../assets/images/LayeredScene/moon/cloud_2.svg);
					}
					&.c-3 {
						top: 60%;
						left: 55%;
						background-image: url(../assets/images/LayeredScene/moon/cloud_3.svg);
					}
					&.c-4 {
						top: 50%;
						left: 75%;
						background-image: url(../assets/images/LayeredScene/moon/cloud_4.svg);
					}
				}
			}
		}

		.hero {
			display: grid;
			grid-template-columns: 1fr;
			gap: 30px;
			max-width: 500px;
			z-index: 1;

			h1 {
				font-size: 100px;
				line-height: 0.8;
			}
			.hero-title {
				line-height: 1.2;
			}
			.description {
				max-width: 350px;
			}
		}
		@media screen and (max-width: $tab) {
			.moon-scene {
				.layers {
					.moon-and-light {
						.moon {
							width: 200px;
							height: 200px;
						}
						.moonlight {
							width: 300px;
							height: 300px;
							&.m-1 {
								top: calc(50% - 200px);
								left: calc(70% - 200px);
								width: 300px;
								height: 300px;
							}
							&.m-2 {
								top: calc(50% - 250px);
								left: calc(70% - 250px);
								width: 400px;
								height: 400px;
								transition-duration: 0.3s;
							}
							&.m-3 {
								top: calc(50% - 300px);
								left: calc(70% - 300px);
								width: 500px;
								height: 500px;
								transition-duration: 0.4s;
							}
							&.m-4 {
								top: calc(50% - 350px);
								left: calc(70% - 350px);
								width: 600px;
								height: 600px;
								transition-duration: 0.5s;
							}
						}
						&:hover {
							.moonlight {
								opacity: 0.8;
							}
						}
					}

					.cloud {
						height: 150px;
						&.c-1 {
							top: 15%;
							left: 25%;
						}
						&.c-2 {
							top: 20%;
							left: 70%;
						}
						&.c-3 {
							top: 40%;
							left: 25%;
						}
						&.c-4 {
							top: 60%;
							left: 55%;
						}
					}
				}
			}
			.hero {
				left: 90px;
				bottom: 90px;
				position: absolute;
				display: grid;
				grid-template-columns: 1fr;
				gap: 30px;
				max-width: 400px;

				h1 {
					font-size: 100px;
					line-height: 0.8;
				}
				.hero-title {
					line-height: 1.2;
				}
				.description {
					max-width: 350px;
				}
			}
		}
		@media screen and (max-width: $mob) {
		}
	}

	.projects-page {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		gap: 30px;
		justify-items: center;
		align-content: center;
		// height: calc(100vh - 180px);
		padding: 90px 10%;
		.page-wrap {
			display: grid;
			grid-template-columns: 1fr;
			gap: 30px;
			height: 100%;
			align-content: center;
			.projects-title {
				padding-left: 20px;
			}
		}

		@media screen and (max-width: $tab) {
		}
		@media screen and (max-width: $mob) {
		}
	}

	.experience-page {
		display: grid;
		justify-items: center;
		padding: 90px 8vw;

		.page-wrap {
			display: grid;
			grid-template-columns: 1fr;
			gap: 100px;
			max-width: 1000px;

			.experience-block {
				display: grid;
				grid-template-columns: auto auto 1fr;
				gap: 30px;
				justify-content: flex-start;
				align-items: flex-start;

				.title {
					display: flex;
					flex-direction: column;
					width: 300px;
					justify-items: flex-start;
					line-height: 1.1;
					margin-top: -5px;
					text-align: end;
				}
				.line {
					width: 3px;
					height: 100%;
					background-color: white;
				}
				.details {
					display: flex;
					flex-direction: column;
					gap: 10px;
					margin: 0;
					padding: 0;

					&.row {
						.text-item {
							flex-direction: row;
						}
					}
					&.indent {
						.text-item {
							p {
								margin-left: 30px;
							}
						}
					}
					.text-item {
						display: flex;
						flex-direction: column;
						gap: 10px;

						p {
							font-size: 24px;
							line-height: 1.3;
						}
					}
					&.gap-increase {
						gap: 30px;
					}
				}
			}
		}
		@media screen and (max-width: $tab) {
			.page-wrap {
				.exp-hero {
					grid-template-columns: 1fr;
					grid-template-rows: auto auto auto;
					.title {
						border-right: none;
						// border-bottom: white solid 3px;
					}
				}
			}
		}
		@media screen and (max-width: $mob) {
		}
	}

	.about-page {
		display: grid;
		grid-template-columns: 1fr;
		justify-items: center;
		// height: calc(200vh - 180px - 65px);
		padding: 90px 10%;

		.page-wrap {
			display: grid;
			grid-template-columns: 1fr;
			gap: 60px;
			max-width: 1000px;

			.section {
				display: grid;
				grid-template-columns: 1fr auto;
				gap: 60px;

				.title {
					justify-self: flex-end;
				}
				.details {
					display: flex;
					flex-direction: column;
					gap: 50px;
					justify-self: flex-start;
					max-width: 600px;

					.subtitle {
						font-size: 50px;
						margin-bottom: -20px;
					}
					.detail-item {
						display: flex;
						flex-direction: column;
						gap: 10px;
						// margin-left: 30px;
						.sub-subtitle {
							font-size: 25px;
						}
						p {
							margin-left: 30px;
						}
					}
				}
			}
		}
		@media screen and (max-width: $tab) {
		}
		@media screen and (max-width: $mob) {
		}
	}

	.services-page {
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		justify-items: center;
		padding: 90px 10%;

		.page-wrap {
			display: grid;
			grid-template-columns: 1fr;
			gap: 60px;
			max-width: 1000px;

			.service {
				display: grid;
				grid-template-columns: 1fr auto;
				gap: 60px;

				.title {
					justify-self: flex-end;
				}
				.details {
					display: flex;
					flex-direction: column;
					gap: 30px;
					justify-self: flex-start;
					max-width: 600px;

					.subtitle {
						font-size: 50px;
					}
					.detail-item {
						display: flex;
						flex-direction: column;
						gap: 10px;
						margin-left: 30px;
						.sub-subtitle {
							font-size: 25px;
						}
						p {
							margin-left: 30px;
						}
					}
				}
			}
		}
		@media screen and (max-width: $tab) {
		}
		@media screen and (max-width: $mob) {
		}
	}

	.contact-page {
		display: grid;
		grid-template-columns: 1fr;
		justify-items: center;
		align-content: center;
		align-items: center;
		height: 100vh;
		padding: 90px;
		position: relative;

		.contact-form {
			display: grid;
			grid-template-columns: 1fr;
			gap: 30px;
			justify-items: flex-start;
			width: 400px;
			padding: 30px;
			background-color: #101430da;
			border-radius: 10px;
			z-index: 2;
			.form-field {
				display: flex;
				flex-direction: column;
				gap: 15px;
				width: 100%;
				input {
					height: 40px;
					padding: 0 10px;
					border-radius: 5px;
					border: none;
					outline: none;
					background-color: #ffffff33;
					color: white;
					&:focus-visible {
						outline: none;
					}
					&:-webkit-autofill {
						-webkit-text-fill-color: white;
						box-shadow: 0 0 0px 100px #161b3a inset;
						-webkit-box-shadow: 0 0 0px 100px #161b3a inset;
					}
				}
				textarea {
					padding: 10px;
					border-radius: 5px;
					border: none;
					background-color: #ffffff33;
					color: white;
					&:focus-visible {
						outline: none;
					}
				}
			}
			button {
				display: flex;
				align-items: center;
				padding: 2px;
				font-size: 25px;
				border-radius: 25px;
				color: #cccedd;
				background-color: transparent;
				transition: all 0.2s ease;
				box-shadow: -2px -2px 6px #39417a, 2px 2px 6px #00000080;
				cursor: pointer;

				.inner {
					padding: 8px 30px;
					border-radius: 25px;
					&:active {
						box-shadow: inset -2px -2px 5px #39417a, inset 2px 2px 5px #00000080;
					}
				}
				&:hover {
					color: #e4e5ee;
					box-shadow: -2px -2px 3px #39417a, 2px 2px 3px #00000080;
				}
				&.active {
					color: white;
					box-shadow: -2px -2px 3px #39417a, 2px 2px 3px #00000080;
					background-color: #0000001c;
				}
			}
		}

		.satellite {
			position: absolute;
			left: 100px;
			top: 100px;
			img {
				width: 300px;
				height: 300px;
			}
		}

		.earth {
			position: absolute;
			right: -200px;
			bottom: -200px;
			width: 800px;
			height: 800px;
			img {
				width: 800px;
				height: 800px;
			}
		}
		@media screen and (max-width: $tab) {
		}
		@media screen and (max-width: $mob) {
		}
	}
}
